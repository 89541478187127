<template>
  <div class="locations p-8 mx-auto max-w-5xl">
    <div class="max-w-4xl space-y-6">
      <div class="flex justify-between pb-2 items-center">
        <h3 class="text-3xl font-semibold">
          Location
        </h3>
        <router-link
          v-if="!isLoading && !location_err"
          :to="`${route.path}/edit`"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
        >
          <span class="hover-target pt-1 font-medium">Edit</span>
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </router-link>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div
        v-if="!isLoading && location_err"
        class="text-center text-red-600 font-semibold"
      >
        Error loading location: {{ location_err }}
      </div>

      <template v-if="!isLoading && !location_err">
        <div class="shadow overflow-hidden border border-gray-200">
          <table class="w-full divide-y divide-gray-200">
            <tbody>
              <tr class="hover:bg-gray-50 border-b">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                >
                  <div>Address</div>
                  <div
                    @click="
                      locationMap(
                        `${location.location_address1}, ` +
                          (location.location_address2
                            ? `${location.location_address2}, `
                            : '') +
                          `${location.location_city} ${location.location_state} ${location.location_zip}`,
                        location.location_type,
                        location.id
                      )
                    "
                    class="cursor-pointer lowercase"
                  >
                    map
                  </div>
                </td>
                <td class="px-6 py-4 text-sm text-gray-500 w-full">
                  <div>{{ location.location_address1 }}</div>
                  <div v-if="location.location_address2">
                    {{ location.location_address2 }}
                  </div>
                  <div>
                    {{ location.location_city }} {{ location.location_state }}
                    {{ location.location_zip }}
                  </div>
                </td>
                <td
                  class="p-4 flex justify-center items-center gap-2 whitespace-nowrap"
                >
                  <template v-if="location.location_type == 'ROOFTOP'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-green-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div>
                      100% match
                    </div>
                  </template>
                  <template
                    v-else-if="
                      location.location_type == 'RANGE_INTERPOLATED' ||
                        location.location_type == 'GEOMETRIC_CENTER'
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-yellow-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div>
                      80% match
                    </div>
                  </template>
                  <template v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <div>No match {{ location.location_type }}</div>
                  </template>
                </td>
              </tr>
              <tr class="hover:bg-gray-50 border-b">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                >
                  <div>Location Notes</div>
                  <div>(Internal Use)</div>
                </td>
                <td
                  colspan="2"
                  class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap"
                >
                  {{ location.location_notes }}
                </td>
              </tr>
              <tr class="hover:bg-gray-50">
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
                >
                  Status
                </td>
                <td colspan="2" class="px-6 py-4 text-sm text-gray-500">
                  {{ location.location_status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex justify-between">
          <h3 class="text-xl font-semibold ">
            Customers
          </h3>
          <div
            @click="addCustomer()"
            class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700 cursor-pointer"
          >
            <span class="hover-target pt-1 font-medium">Add Customer</span>
            <svg
              class="h-7 w-7 text-blue-600 hover:text-blue-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>

        <div
          v-for="customer in filteredCustomers"
          :key="customer.id"
          class="shadow overflow-hidden border border-gray-200"
        >
          <table class="w-full divide-y divide-gray-200">
            <thead class="text-left bg-gray-100 font-medium">
              <tr
                @click="goTo(`/customers/${customer.id}`)"
                class="border-b text-sm text-gray-900 hover:text-gray-600"
              >
                <th colspan="6" class="px-6 py-4 cursor-pointer">
                  <div>{{ customer.billing_business }}</div>
                  <div>{{ customer.customer_name }}</div>
                  <div>{{ customer.customer_phone }}</div>
                  <div v-if="customer.customer_email">
                    {{ customer.customer_email }}
                  </div>
                </th>
              </tr>
              <tr class="text-xs text-gray-500 uppercase">
                <th scope="col" class="px-6 py-3 tracking-wider">
                  Status
                </th>
                <th scope="col" class="px-6 py-3 tracking-wider">
                  Number
                </th>
                <th scope="col" class="px-6 py-3 tracking-wider">
                  Created
                </th>
                <th scope="col" class="px-6 py-3 tracking-wider">
                  Total Price
                </th>
                <th scope="col" class="px-6 py-3 tracking-wider">
                  Assigned To
                </th>
                <th scope="col" class="px-6 py-3 tracking-wider">
                  Accepted
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="quote in quotes" :key="quote.id">
                <tr
                  v-if="customer.id == quote.customer_id"
                  class="text-sm text-gray-500 hover:bg-gray-50 border-b cursor-pointer"
                  @click="goTo(`/quotes/${quote.id}`)"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      :class="{
                        'px-2 rounded-full bg-green-500 text-white':
                          quote.quote_status == 'pending',
                        'px-2 rounded-full bg-blue-500 text-white':
                          quote.quote_status == 'assigned',
                        'px-2 rounded-full bg-purple-500 text-white':
                          quote.quote_status == 'processed',
                        'px-2 rounded-full bg-gray-500 text-white':
                          quote.quote_status == 'emailed',
                        'px-2 rounded-full bg-black text-white':
                          quote.quote_status == 'completed',
                      }"
                      class="font-semibold capitalize"
                    >
                      {{ quote.quote_status }}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ quote.sequence_number }}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ fromSeconds(quote.createdAt?.seconds) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{
                      quote.override_price
                        ? quote.override_price
                        : quote.total_price
                    }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ usernameFromId(quote.assigned_to) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ quote.accepted ? "&check;" : "" }}
                  </td>
                </tr>
              </template>
              <tr
                @click="goToAddQuote(location, customer)"
                class="text-sm text-gray-500 hover:bg-gray-50 hover:text-blue-500"
              >
                <td colspan="6" class="px-6 py-4 cursor-pointer">
                  Create new quote
                  <span class="font-semibold text-blue-500">&gt;</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <hr class="border-b border-blue-500" />
        </div>

        <div
          v-if="customers_err || quotes_err || users_err"
          class="text-center text-red-600 font-semibold"
        >
          {{ customers_err }}
          {{ quotes_err }}
          {{ users_err }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import getDocument from "@/composables/getDocument";
import getCollection from "@/composables/getCollection";
import useLuxon from "@/composables/useLuxon";

export default {
  props: ["location_id", "user"],
  emits: ["loadModalMap"],
  setup(props, context) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();

    const business_id = props.user.claims.business_id ?? "";

    const { error: location_err, document: location } = getDocument(
      `businesses/${business_id}/locations`,
      props.location_id
    );

    const { error: customers_err, documents: customers } = getCollection(
      `businesses/${business_id}/customers`
    );

    const { error: quotes_err, documents: quotes } = getCollection(
      `businesses/${business_id}/quotes`
    );

    const filteredCustomers = computed(() => {
      return customers.value?.filter(
        (c) =>
          c.customer_status == "active" &&
          location.value.customers?.includes(c.id)
      );
    });

    const filteredQuotes = computed(() => {
      return quotes.value?.filter((q) => q.location_id == location.value.id);
    });

    const { error: users_err, documents: users } = getCollection(
      `businesses/${business_id}/users`
    );

    watch(quotes, () => {
      quotes.value?.sort((a, b) => {
        return b.sequence_number - a.sequence_number;
      });
    });

    watchEffect(() => {
      if (location.value && customers.value && quotes.value && users.value) {
        isLoading.value = false;
      }
    });

    const usernameFromId = (id) => {
      if (id && users.value) {
        const assigned = users.value.filter((u) => u.id == id).shift();
        return assigned.user_name;
      }
      return;
    };

    const addCustomer = () => {
      router.push({
        name: "CustomersAdd",
        params: { location_id: location.value.id },
      });
    };

    const goTo = (url) => {
      router.push(url);
    };

    const goToAddQuote = (location, customer) => {
      router.push(`/quotes/add/${customer.id}/${location.id}`);
    };

    const { fromSeconds } = useLuxon();

    const locationMap = (address, location_type, location_id) => {
      const data = {
        business_id: business_id,
        address: address,
        location_type: location_type,
        location_id: location_id,
      };
      context.emit("loadModalMap", data);
    };

    return {
      isLoading,
      route,
      location_err,
      location,
      customers_err,
      customers,
      quotes_err,
      quotes,
      filteredCustomers,
      filteredQuotes,
      users_err,
      users,
      usernameFromId,
      addCustomer,
      goTo,
      goToAddQuote,
      fromSeconds,
      locationMap,
    };
  },
};
</script>
